import React, { useState } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import PasswordRequirements from '../components/PasswordRequirements';
import { resetPassword } from '../services/auth';
import { getUrlParamValue } from '../utils/data';

import { useNavigate } from 'react-router-dom';

import { Modal, Spinner } from 'react-bootstrap';
import i18n from '../utils/i18n';

type FormValues = {
  password: string;
  confirmPassword: string;
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], `Password must match`)
    .required('Required')
    .trim(),
});

const ResetPassword = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [passwordRequirementsMet, setPasswordRequirementsMet] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);

      const token = getUrlParamValue('token');

      await resetPassword(values.password, token);

      setShowConfirmationModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    isValid,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: { password: '', confirmPassword: '' },
    validationSchema,
    onSubmit,
    validateOnBlur: false,
    initialErrors: {
      password: 'Required',
      confirmPassword: 'Required',
    },
  });

  return (
    <>
      <div className="main-wrap">
        <div className="row">
          <div className="col-xl-5 justify-content-center align-items-center d-flex">
            <img
              src="assets/images/logo-login.png"
              className="login-logo"
              alt=""
            />
          </div>

          <div className="col-xl-7 login-block align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size mb-4">
                  {i18n.t<string>('ResetPassword.title')}
                </h2>
                <form className="mb-3">
                  <div className="form-group mb-2 d-flex flex-row align-items-center">
                    <i
                      className="font-sm ti-lock text-grey-500 pe-0"
                      style={{
                        position: 'absolute',
                        left: 30,
                      }}
                    />
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="style2-input px-5 form-control text-grey-900 font-xss ls-3"
                      placeholder={i18n.t<string>('ResetPassword.form.newPassword')}
                      id="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={loading}
                      value={values.password}
                    />
                    <i
                      className={`font-sm ${
                        showPassword ? 'feather-eye' : 'feather-eye-off'
                      } text-grey-500 pe-0`}
                      style={{
                        position: 'absolute',
                        right: 30,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  </div>
                  <PasswordRequirements
                    password={values.password}
                    onMeetAllRequirements={() => {
                      setPasswordRequirementsMet(true);
                    }}
                  />
                  <div className="form-group mt-3 d-flex flex-row align-items-center">
                    <i
                      className="font-sm ti-lock text-grey-500 pe-0"
                      style={{
                        position: 'absolute',
                        left: 30,
                      }}
                    />
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      className="style2-input px-5 form-control text-grey-900 font-xss ls-3"
                      placeholder={i18n.t<string>('ResetPassword.form.confirmNewPassword')}
                      id="confirmPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={loading}
                      value={values.confirmPassword}
                    />
                    <i
                      className={`font-sm ${
                        showConfirmPassword ? 'feather-eye' : 'feather-eye-off'
                      } text-grey-500 pe-0`}
                      style={{
                        position: 'absolute',
                        right: 30,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                    />
                  </div>
                  <span className="text-danger font-xsss mb-2">
                    {touched.confirmPassword && errors.confirmPassword
                      ? errors.confirmPassword
                      : ''}
                  </span>
                </form>

                {loading ? (
                  <div className="d-flex justify-content-center">
                    <Spinner
                      animation="border"
                      role="status"
                      className="text-warning"
                    />
                  </div>
                ) : (
                  <div className="col-sm-12 p-0 text-left">
                    <div className="form-group mb-1">
                      <button
                        onClick={() => handleSubmit()}
                        disabled={!isValid || !passwordRequirementsMet}
                        className="form-control text-center style2-input text-white fw-600 bg-warning border-0 p-0"
                        style={
                          !isValid || !passwordRequirementsMet
                            ? { opacity: 0.5 }
                            : { opacity: 1 }
                        }
                      >
                        {i18n.t<string>('ResetPassword.button')}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
        >
          <Modal.Header
            closeButton
            onClick={() => setShowConfirmationModal(false)}
            className="bg-white"
            style={{
              border: 0,
              borderRadius: 0,
              padding: 20,
              borderTopLeftRadius: 10,
              WebkitBorderTopRightRadius: 10,
            }}
          />
          <Modal.Body
            className="bg-white"
            style={{
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            <p className="font-xs text-black">
              {i18n.t<string>('ResetPassword.confirmationMessage')}
            </p>
            <div className="form-group mb-1">
              <button
                onClick={() => {
                  setShowConfirmationModal(false);
                  navigate('/login');
                }}
                className="form-control text-center style2-input text-white fw-600 bg-warning border-0 p-0"
              >
                {i18n.t<string>('Global.ok')}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ResetPassword;
