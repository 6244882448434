import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import { Spinner } from 'react-bootstrap';
import LeftMenu from '../components/LeftMenu';
import { session } from '../signals/session';

import CurrencyInput from 'react-currency-input-field';
import i18n from '../utils/i18n';
import { savePlan } from '../services/creator';

const validationSchema = Yup.object().shape({
  instagramAccount: Yup.string(),
  xAccount: Yup.string(),
  tiktokAccount: Yup.string(),
  site: Yup.string(),
  monthlySubscription: Yup.number().required('Required'),
});

type FormValues = {
  instagramAccount: string;
  xAccount: string;
  tiktokAccount: string;
  site: string;
  monthlySubscription: number;
};

// const plans: { value: number; id: string }[] = [
//   { id: '1', value: 10 },
//   { id: '3', value: 25 },
//   { id: '4', value: 50 },
//   { id: '5', value: 100 },
//   { id: '6', value: 150 },
// ];

const Profile = () => {
  const [loading, setLoading] = useState(false);
  // const [selectedPlanId, setSelectedPlanId] = useState('1');

  const navigate = useNavigate();

  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);
      await savePlan(values.monthlySubscription);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, handleChange, setFieldValue, isValid, values } =
    useFormik({
      initialValues: {
        instagramAccount: '',
        xAccount: '',
        tiktokAccount: '',
        site: '',
        monthlySubscription: 0.0,
      },
      validationSchema,
      onSubmit,
      validateOnBlur: false,
    });

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
                  <button
                    onClick={() => navigate(-1)}
                    className="d-inline-block mt-2 bg-transparent border-0"
                  >
                    <i className="ti-arrow-left font-sm text-black"></i>
                  </button>
                  <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                    {i18n.t<string>('CreatorSettings.title')}
                  </h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-5">
                      <div className="form-group">
                        <div className="d-flex flex-row justify-content-center">
                          <label className="mont-font fw-600 font-xsss mb-2 me-2">
                            {i18n.t<string>(
                              'CreatorSettings.monthlySubscription'
                            )}
                          </label>
                        </div>
                        <div className="d-flex flex-row justify-content-center">
                          <CurrencyInput
                            prefix="R$"
                            name="monthlySubscription"
                            onValueChange={(e) => {
                              setFieldValue(
                                'monthlySubscription',
                                e ? Number(e) : 0.0
                              );
                            }}
                            value={values.monthlySubscription}
                            maxLength={4}
                            decimalSeparator=","
                            allowNegativeValue={false}
                            groupSeparator="."
                            style={{
                              fontSize: 26,
                              padding: 5,
                              fontWeight: 700,
                              color: '#6c757d',
                              textAlign: 'center',
                              borderWidth: 0,
                              borderBottomWidth: 1,
                              borderBottomColor: '#ced4da',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <div className="d-flex flex-row">
                            <label className="mont-font fw-600 font-xsss mb-2 me-2">
                              {i18n.t<string>('CreatorSettings.tiktokAccount')}
                            </label>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="https://www.tiktok.com/usuario"
                            disabled={loading}
                            value={values.tiktokAccount}
                            id="tiktokAccount"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <div className="d-flex flex-row">
                            <label className="mont-font fw-600 font-xsss mb-2 me-2">
                              {i18n.t<string>('CreatorSettings.site')}
                            </label>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={i18n.t<string>('CreatorSettings.site')}
                            disabled={loading}
                            value={values.site}
                            id="site"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <div className="d-flex flex-row">
                            <label className="mont-font fw-600 font-xsss mb-2 me-2">
                              {i18n.t<string>(
                                'CreatorSettings.instagramAccount'
                              )}
                            </label>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="https://www.instagram.com/usuario"
                            disabled={loading}
                            value={values.instagramAccount}
                            id="instagramAccount"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <div className="d-flex flex-row">
                            <label className="mont-font fw-600 font-xsss mb-2 me-2">
                              {i18n.t<string>('CreatorSettings.xAccount')}
                            </label>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="https://x.com/usuario"
                            disabled={loading}
                            value={values.xAccount}
                            id="xAccount"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            {i18n.t<string>('CreatorSettings.username')}
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={session.value.username}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        {loading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            className="text-warning mt-3"
                          />
                        ) : (
                          <button
                            onClick={() => handleSubmit()}
                            disabled={!isValid}
                            type='button'
                            className="bg-warning border-0 text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                            style={!isValid ? { opacity: 0.5 } : { opacity: 1 }}
                          >
                            {i18n.t<string>('CreatorSettings.save')}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Appfooter />
    </>
  );
};

export default Profile;
