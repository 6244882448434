import React, { useState } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Modal, Spinner } from 'react-bootstrap';

// import { useNavigate } from 'react-router-dom';
import { requestForgotPassword } from '../services/auth';
import i18n from '../utils/i18n';

type FormValues = {
  email: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Enter a valid email address').required('Required'),
});

const Forgot = () => {
  // const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);
      await requestForgotPassword(values.email);
    } catch (error) {
      // TODO handle error properly
      console.log(error);
    } finally {
      setShowConfirmationModal(true);
      setLoading(false);
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    isValid,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit,
    validateOnBlur: false,
    initialErrors: {
      email: 'Required',
    },
  });

  return (
    <>
      <div className="main-wrap">
        <div className="row">
          <div className="col-xl-5 justify-content-center align-items-center d-flex">
            <img
              src="assets/images/logo-login.png"
              className="login-logo"
              alt=""
            />
          </div>
          <div className="col-xl-7 login-block align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size mb-4">
                  {i18n.t<string>('ForgotPassword.title')}
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-email text-grey-500 pe-0"></i>
                    <input
                      type="text"
                      id="email"
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      placeholder={i18n.t<string>('ForgotPassword.form.email')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      disabled={loading}
                    />
                    <span className="text-danger font-xsss fw-500">
                      {touched.email && errors.email ? errors.email : ''}
                    </span>
                  </div>
                </form>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <Spinner
                      animation="border"
                      role="status"
                      className="text-warning"
                    />
                  </div>
                ) : (
                  <div className="col-sm-12 p-0 text-left">
                    <div className="form-group mb-1">
                      <button
                        onClick={() => handleSubmit()}
                        disabled={!isValid}
                        className="form-control text-center style2-input text-white fw-600 bg-warning border-0 p-0"
                        style={!isValid ? { opacity: 0.5 } : { opacity: 1 }}
                      >
                        {i18n.t<string>('ForgotPassword.button')}
                      </button>
                      <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32 align-self-center d-flex justify-content-center mt-3">
                        <a href="/login" className="fw-700 ms-1 text-grey-700">
                          {i18n.t<string>('ForgotPassword.backBtn')}
                        </a>
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
        >
          <Modal.Header
            closeButton
            onClick={() => setShowConfirmationModal(false)}
            className="bg-white"
            style={{
              border: 0,
              borderRadius: 0,
              padding: 20,
              borderTopLeftRadius: 10,
              WebkitBorderTopRightRadius: 10,
            }}
          />
          <Modal.Body
            className="bg-white"
            style={{
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            <p className="font-xs text-black">
              {`If you have an account with us, instructions for resetting your password have been sent to: ${values.email}`}
            </p>
            <div className="form-group mb-1">
              <button
                onClick={() => setShowConfirmationModal(false)}
                className="form-control text-center style2-input text-white fw-600 bg-warning border-0 p-0"
              >
                OK
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Forgot;
