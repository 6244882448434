import React, { useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Select from 'react-select';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';

import LeftMenu from '../components/LeftMenu';
import { gendersData, pronounsData, statesData } from '../utils/data';
import i18n from '../utils/i18n';
import { Spinner } from 'react-bootstrap';
import InputMask from 'react-input-mask';

type FormValues = {
  firstName: string;
  lastName: string;
  birthdate: string;
  gender: string;
  pronoun: string;
  cpf: string;
  address: string;
  complement: string;
  city: string;
  state: string;
};

const Creator = () => {
  const scrollableDivRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const identityDocumentInputFile = useRef(null);
  const selfIdentityDocumentInputFile = useRef(null);
  const proofAddressDocumentInputFile = useRef(null);

  const [identityDocument, setIdentityDocument] =
    useState<UploadMedia | null>();
  const [selfIdentityDocument, setSelfIdentityDocument] =
    useState<UploadMedia | null>();
  const [proofAddressDocument, setProofAddressDocument] =
    useState<UploadMedia | null>();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      i18n.t('BecomeCreator.form.errors.required')
    ),
    lastName: Yup.string().required(
      i18n.t('BecomeCreator.form.errors.required')
    ),
    birthdate: Yup.string().required(
      i18n.t('BecomeCreator.form.errors.required')
    ),
    gender: Yup.string().required(i18n.t('BecomeCreator.form.errors.required')),
    pronoun: Yup.string().required(
      i18n.t('BecomeCreator.form.errors.required')
    ),
    cpf: Yup.string().required(i18n.t('BecomeCreator.form.errors.required')),
    address: Yup.string().required(
      i18n.t('BecomeCreator.form.errors.required')
    ),
    complement: Yup.string(),
    city: Yup.string().required(i18n.t('BecomeCreator.form.errors.required')),
    state: Yup.string().required(i18n.t('BecomeCreator.form.errors.required')),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      birthdate: '',
      gender: '',
      pronoun: '',
      cpf: '',
      address: '',
      complement: '',
      city: '',
      state: '',
    },
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    initialErrors: {
      firstName: i18n.t('BecomeCreator.form.errors.required'),
      lastName: i18n.t('BecomeCreator.form.errors.required'),
      birthdate: i18n.t('BecomeCreator.form.errors.required'),
      gender: i18n.t('BecomeCreator.form.errors.required'),
      pronoun: i18n.t('BecomeCreator.form.errors.required'),
      cpf: i18n.t('BecomeCreator.form.errors.required'),
      address: i18n.t('BecomeCreator.form.errors.required'),
      city: i18n.t('BecomeCreator.form.errors.required'),
      state: i18n.t('BecomeCreator.form.errors.required'),
    },
  });

  function onSubmit(values: FormValues) {
    try {
      // if (!isValidDate(new Date(values.birthdate))) {
      //   setFieldError('birthdate', 'Invalid Date');
      //   return;
      // }

      // const parsedDate = parse(values.birthdate, 'dd/MM/yyyy', new Date());
      // console.log(parsedDate)

      // const formattedDate = format(parsedDate.toISOString(), 'yyyy-MM-dd');

      setLoading(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const onIdentityDocumentFileSelected = (event) => {
    const file = event.target.files[0];

    const identityDocument: UploadMedia = {
      id: '',
      type: 'IMAGE',
      url: URL.createObjectURL(file),
      file: file,
      uploading: false,
      uploaded: false,
      failed: false,
      uploadUrl: '',
    };

    console.log('added');

    setIdentityDocument(identityDocument);
  };

  const onSelfIdentityDocumentFileSelected = (event) => {
    const file = event.target.files[0];

    const selfIdentityDocument: UploadMedia = {
      id: '',
      type: 'IMAGE',
      url: URL.createObjectURL(file),
      file: file,
      uploading: false,
      uploaded: false,
      failed: false,
      uploadUrl: '',
    };

    setSelfIdentityDocument(selfIdentityDocument);
  };

  const onProofAddressDocumentFileSelected = (event) => {
    const file = event.target.files[0];

    const proofAddressDocument: UploadMedia = {
      id: '',
      type: 'IMAGE',
      url: URL.createObjectURL(file),
      file: file,
      uploading: false,
      uploaded: false,
      failed: false,
      uploadUrl: '',
    };

    setProofAddressDocument(proofAddressDocument);
  };

  const genders = useMemo(() => {
    const genders: { label: string; value: string }[] = [];

    for (const gender of gendersData) {
      genders.push({
        label: i18n.t(`Genders.${gender.value}`),
        value: gender.value,
      });
    }

    return genders;
  }, []);

  const pronouns = useMemo(() => {
    const pronouns: { label: string; value: string }[] = [];

    for (const pronoun of pronounsData) {
      pronouns.push({
        label: i18n.t(`Pronouns.${pronoun.value}`),
        value: pronoun.value,
      });
    }

    return pronouns;
  }, []);

  const states = useMemo(() => {
    const states: { label: string; value: string }[] = [];

    for (const state of statesData) {
      states.push({
        label: state.value,
        value: state.value,
      });
    }

    return states;
  }, []);

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active" ref={scrollableDivRef}>
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
                  <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                    {i18n.t<string>('BecomeCreator.title')}
                  </h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            {i18n.t<string>('BecomeCreator.form.firstName')}
                          </label>
                          <input
                            type="text"
                            className="form-control font-xss"
                            disabled={loading}
                            value={values.firstName}
                            placeholder={i18n.t<string>(
                              'BecomeCreator.form.firstName'
                            )}
                            name="firstName"
                            id="firstName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {touched.firstName && errors.firstName
                              ? errors.firstName
                              : ''}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            {i18n.t<string>('BecomeCreator.form.lastName')}
                          </label>
                          <input
                            type="text"
                            className="form-control font-xss"
                            disabled={loading}
                            value={values.lastName}
                            placeholder={i18n.t<string>(
                              'BecomeCreator.form.lastName'
                            )}
                            name="lastName"
                            id="lastName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {touched.lastName && errors.lastName
                              ? errors.lastName
                              : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            {i18n.t<string>('BecomeCreator.form.dateOfBirth')}
                          </label>
                          <InputMask
                            className="form-control"
                            mask="99/99/9999"
                            value={values.birthdate}
                            placeholder="DD/MM/YYYY"
                            name="birthdate"
                            id="birthdate"
                            onChange={handleChange}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {touched.birthdate && errors.birthdate
                              ? errors.birthdate
                              : ''}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            {i18n.t<string>('BecomeCreator.form.gender')}
                          </label>
                          <Select
                            onChange={(gender) => {
                              setFieldValue('gender', gender.value);
                            }}
                            value={{
                              label: values.gender
                                ? i18n.t(`Genders.${values.gender}`)
                                : '',
                              value: values.gender,
                            }}
                            options={genders}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {touched.gender && errors.gender
                              ? errors.gender
                              : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            {i18n.t<string>('BecomeCreator.form.pronoun')}
                          </label>
                          <Select
                            onChange={(pronoun) => {
                              setFieldValue('pronoun', pronoun.value);
                            }}
                            value={{
                              label: values.pronoun
                                ? i18n.t(`Pronouns.${values.pronoun}`)
                                : '',
                              value: values.pronoun,
                            }}
                            options={pronouns}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {touched.pronoun && errors.pronoun
                              ? errors.pronoun
                              : ''}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            {i18n.t<string>('BecomeCreator.form.cpf')}
                          </label>
                          <InputMask
                            className="form-control"
                            mask="999.999.999-99"
                            placeholder="000.000.000-00"
                            value={values.cpf}
                            name="cpf"
                            id="cpf"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            {i18n.t<string>(
                              'BecomeCreator.form.attachSelfPhoto'
                            )}
                          </label>
                          <button
                            onClick={() => {
                              selfIdentityDocumentInputFile.current.click();
                            }}
                            className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 border-0 bg-warning p-2"
                            style={{
                              opacity: 1,
                            }}
                          >
                            <i className="font-md text-white feather-paperclip me-2"></i>
                            <span className="text-white">
                              {i18n.t<string>('BecomeCreator.form.addFile')}
                            </span>
                          </button>
                          {selfIdentityDocument && (
                            <div
                              key={selfIdentityDocument.url}
                              className="position-relative"
                            >
                              <img
                                onClick={() => {
                                  // setImagePreviewModal(true);
                                  // setSelectedImageIndex(index);
                                }}
                                src={selfIdentityDocument.url}
                                alt="img preview"
                                style={{
                                  maxWidth: '100px',
                                  maxHeight: '100px',
                                  margin: '5px',
                                  cursor: 'pointer',
                                  borderRadius: 10,
                                  opacity:
                                    selfIdentityDocument.uploading ||
                                    selfIdentityDocument.failed
                                      ? 0.2
                                      : 1,
                                }}
                              />
                              {selfIdentityDocument.failed && (
                                <>
                                  <i
                                    className="font-md text-warning feather-alert-circle me-2"
                                    style={{
                                      cursor: 'pointer',
                                      position: 'absolute',
                                      top: 0,
                                      right: -10,
                                      zIndex: 1,
                                    }}
                                  />
                                  <i
                                    onClick={() => false}
                                    className="font-xl text-warning feather-refresh-ccw me-2"
                                    style={{
                                      cursor: 'pointer',
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      zIndex: 1,
                                    }}
                                  />
                                </>
                              )}
                              {selfIdentityDocument.uploading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  size="sm"
                                  className="text-warning mt-2"
                                />
                              ) : (
                                <i
                                  onClick={() => setSelfIdentityDocument(null)}
                                  className="font-md text-warning feather-trash-2 me-2"
                                  style={{ cursor: 'pointer' }}
                                />
                              )}
                            </div>
                          )}
                          <input
                            type="file"
                            id="file"
                            ref={selfIdentityDocumentInputFile}
                            multiple
                            onChange={onSelfIdentityDocumentFileSelected}
                            style={{ display: 'none' }}
                            accept="image/*"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            {i18n.t<string>(
                              'BecomeCreator.form.attachPhotoIdentityCard'
                            )}
                          </label>
                          <button
                            type='button'
                            onClick={() => {
                              identityDocumentInputFile.current.click();
                            }}
                            className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 border-0 bg-warning p-2"
                            style={{
                              // cursor: 'pointer',
                              opacity: 1,
                            }}
                          >
                            <i className="font-md text-white feather-paperclip me-2"></i>
                            <span className="text-white">
                              {i18n.t<string>('BecomeCreator.form.addFile')}
                            </span>
                          </button>
                          {identityDocument && (
                            <div
                              key={identityDocument.url}
                              className="position-relative"
                            >
                              <img
                                onClick={() => {
                                  // setImagePreviewModal(true);
                                  // setSelectedImageIndex(index);
                                }}
                                src={identityDocument.url}
                                alt="img preview"
                                style={{
                                  maxWidth: '100px',
                                  maxHeight: '100px',
                                  margin: '5px',
                                  cursor: 'pointer',
                                  borderRadius: 10,
                                  opacity:
                                    identityDocument.uploading ||
                                    identityDocument.failed
                                      ? 0.2
                                      : 1,
                                }}
                              />
                              {identityDocument.failed && (
                                <>
                                  <i
                                    className="font-md text-warning feather-alert-circle me-2"
                                    style={{
                                      cursor: 'pointer',
                                      position: 'absolute',
                                      top: 0,
                                      right: -10,
                                      zIndex: 1,
                                    }}
                                  />
                                  <i
                                    onClick={() => false}
                                    className="font-xl text-warning feather-refresh-ccw me-2"
                                    style={{
                                      cursor: 'pointer',
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      zIndex: 1,
                                    }}
                                  />
                                </>
                              )}
                              {identityDocument.uploading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  size="sm"
                                  className="text-warning mt-2"
                                />
                              ) : (
                                <i
                                  onClick={() => setIdentityDocument(null)}
                                  className="font-md text-warning feather-trash-2 me-2"
                                  style={{ cursor: 'pointer' }}
                                />
                              )}
                            </div>
                          )}
                          <input
                            type="file"
                            id="file"
                            ref={identityDocumentInputFile}
                            onChange={onIdentityDocumentFileSelected}
                            style={{ display: 'none' }}
                            accept="image/*"
                          />
                        </div>
                      </div>
                    </div>
                    <h4 className="font-xs text-black fw-600 mb-4 mt-4">
                      {i18n.t<string>('BecomeCreator.form.addressSectionTitle')}
                    </h4>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            {i18n.t<string>('BecomeCreator.form.address')}
                          </label>
                          <input
                            type="text"
                            className="form-control font-xss"
                            disabled={loading}
                            value={values.address}
                            placeholder={i18n.t<string>(
                              'BecomeCreator.form.address'
                            )}
                            name="address"
                            id="address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {touched.address && errors.address
                              ? errors.address
                              : ''}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            {i18n.t<string>('BecomeCreator.form.complement')}
                          </label>
                          <input
                            type="text"
                            className="form-control font-xss"
                            disabled={loading}
                            value={values.complement}
                            placeholder={i18n.t<string>(
                              'BecomeCreator.form.complement'
                            )}
                            name="complement"
                            id="complement"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {touched.complement && errors.complement
                              ? errors.complement
                              : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            {i18n.t<string>('BecomeCreator.form.city')}
                          </label>
                          <input
                            type="text"
                            className="form-control font-xss"
                            disabled={loading}
                            value={values.city}
                            placeholder={i18n.t<string>(
                              'BecomeCreator.form.city'
                            )}
                            name="city"
                            id="city"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {touched.city && errors.city ? errors.city : ''}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            {i18n.t<string>('BecomeCreator.form.state')}
                          </label>
                          <Select
                            onChange={(state) => {
                              setFieldValue('state', state.value);
                            }}
                            value={{
                              label: values.state,
                              value: values.state,
                            }}
                            options={states}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {touched.gender && errors.gender
                              ? errors.gender
                              : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                          {i18n.t<string>(
                            'BecomeCreator.form.attachPhotoProofAddress'
                          )}
                        </label>
                        <button
                          onClick={() => {
                            proofAddressDocumentInputFile.current.click();
                          }}
                          className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 border-0 bg-warning p-2"
                          style={{
                            // cursor: 'pointer',
                            opacity: 1,
                          }}
                        >
                          <i className="font-md text-white feather-paperclip me-2"></i>
                          <span className="text-white">
                            {i18n.t<string>('BecomeCreator.form.addFile')}
                          </span>
                        </button>
                        {proofAddressDocument && (
                          <div
                            key={proofAddressDocument.url}
                            className="position-relative"
                          >
                            <img
                              onClick={() => {
                                // setImagePreviewModal(true);
                                // setSelectedImageIndex(index);
                              }}
                              src={proofAddressDocument.url}
                              alt="img preview"
                              style={{
                                maxWidth: '100px',
                                maxHeight: '100px',
                                margin: '5px',
                                cursor: 'pointer',
                                borderRadius: 10,
                                opacity:
                                  proofAddressDocument.uploading ||
                                  proofAddressDocument.failed
                                    ? 0.2
                                    : 1,
                              }}
                            />
                            {proofAddressDocument.failed && (
                              <>
                                <i
                                  className="font-md text-warning feather-alert-circle me-2"
                                  style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: 0,
                                    right: -10,
                                    zIndex: 1,
                                  }}
                                />
                                <i
                                  onClick={() => false}
                                  className="font-xl text-warning feather-refresh-ccw me-2"
                                  style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 1,
                                  }}
                                />
                              </>
                            )}
                            {proofAddressDocument.uploading ? (
                              <Spinner
                                animation="border"
                                role="status"
                                size="sm"
                                className="text-warning mt-2"
                              />
                            ) : (
                              <i
                                onClick={() => setProofAddressDocument(null)}
                                className="font-md text-warning feather-trash-2 me-2"
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                          </div>
                        )}
                        <input
                          type="file"
                          id="file"
                          ref={proofAddressDocumentInputFile}
                          multiple
                          onChange={onProofAddressDocumentFileSelected}
                          style={{ display: 'none' }}
                          accept="image/*"
                        />
                      </div>
                    </div>
                    <div className="mt-5 border-0 bg-transparent">
                      <p className="font-xsss mt-1">
                        {i18n.t<string>('BecomeCreator.acceptTermsText1')}{' '}
                        <span
                          className="text-warning fw-700"
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                          onClick={() => {
                            window
                              .open(
                                'https://pages.silksecret.me/docs/termos',
                                '_blank'
                              )
                              .focus();
                          }}
                        >
                          {i18n.t<string>('BecomeCreator.acceptTermsText2')}
                        </span>{' '}
                        {i18n.t<string>('BecomeCreator.acceptTermsText3')}{' '}
                        <span
                          className="text-warning fw-700"
                          onClick={() => {
                            window
                              .open(
                                'https://pages.silksecret.me/docs/privacidade',
                                '_blank'
                              )
                              .focus();
                          }}
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                        >
                          {i18n.t<string>('BecomeCreator.acceptTermsText4')}
                        </span>{' '}
                        {i18n.t<string>('BecomeCreator.acceptTermsText5')}
                      </p>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-12">
                        {loading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            className="text-warning mt-3"
                          />
                        ) : (
                          <button
                            onClick={() => handleSubmit()}
                            disabled={!isValid}
                            className="bg-warning border-0 text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                            style={!isValid ? { opacity: 0.5 } : { opacity: 1 }}
                          >
                            {i18n.t<string>('BecomeCreator.button')}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                  {/* <DatePicker
                    initialDate={values.birthdate}
                    onSave={(
                      date: string,
                      resetToDate: (date: string) => void
                    ) => {
                      setIsDatePickerVisible(false);

                      if (isFuture(date)) {
                        setFieldError(
                          'birthdate',
                          'Select a valid date of birth'
                        );
                        return;
                      }

                      if (isMinor(date)) {
                        setFieldError(
                          'birthdate',
                          'You must be at least 18 years old'
                        );
                        return;
                      }
                      setFieldValue('birthdate', date);

                      // resetToDate(values.birthdate);
                    }}
                    onCancel={() => setIsDatePickerVisible(false)}
                    isVisible={isDatePickerVisible}
                    title="Date of Birth"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Appfooter />
    </>
  );
};

export default Creator;
