import React, { useCallback, useRef, useState, useMemo } from 'react';
import { AxiosError } from 'axios';
import { Spinner } from 'react-bootstrap';

import {
  getImageUploadUrl,
  getVideoUploadUrl,
  post,
  uploadMedia,
} from '../services/post';

import ImageViewer from './ImageViewer';
import TextArea from './TextArea';
import { logout } from '../utils/session';
import ErrorModal from '../modals/ErrorModal';
import { session } from '../signals/session';
import i18n from '../utils/i18n';

type Props = {
  onPost: () => void;
};

const CreatePost: React.FC<Props> = ({ onPost }) => {
  const inputFile = useRef(null);
  const videoFile = useRef(null);
  const canvasRef = useRef(null);

  const [postImages, setPostImages] = useState<UploadMedia[]>([]);
  const [postVideo, setPostVideo] = useState<UploadMedia | null>(null);
  const [imagePreviewModal, setImagePreviewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [text, setText] = useState('');
  const [errorModal, setErrorModal] = useState<{
    isOpen: boolean;
    message?: string;
  }>({
    isOpen: false,
    message: '',
  });

  const canvasToBlob = (canvas: HTMLCanvasElement): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Canvas toBlob failed'));
        }
      }, 'image/png');
    });
  };

  const loadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = src;
    });
  };

  const fetchImage = (src) => {
    return fetch(src)
      .then((response) => response.blob())
      .then(
        (blob) => new File([blob], 'secondImage.png', { type: 'image/png' })
      );
  };

  const onPhotosSelected = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const imageFiles = Array.from(event.target.files ?? []);

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Fetch the image and convert it to a File object
    const watermarkFile = await fetchImage('assets/images/logo-watermark.png');

    // Load the image asynchronously
    const watermarkImg = await loadImage(URL.createObjectURL(watermarkFile));

    const images: UploadMedia[] = [];

    for (const imageFile of imageFiles) {
      try {
        // Load the image asynchronously
        const img = await loadImage(URL.createObjectURL(imageFile));

        // Set the canvas width and height based on the image dimensions
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Calculate the position and size for the second image
        const img2Width = canvas.width / 15;
        const img2Height =
          (watermarkImg.height / watermarkImg.width) * img2Width; // Maintain aspect ratio
        const img2X = 10; // 10px padding from the left
        const img2Y = canvas.height - img2Height - 10; // 10px padding from the bottom

        // const img2X = canvas.width - img2Width; // 10px padding from the right
        // const img2Y = canvas.height - img2Height; // 10px padding from the bottom

        // Draw the second image onto the canvas
        ctx.drawImage(watermarkImg, img2X, img2Y, img2Width, img2Height);

        // Set the font properties
        const fontSize = Math.floor(canvas.width * 0.03); // Font size as 3% of the image width
        ctx.font = `${fontSize}px Open Sans`;
        ctx.fillStyle = 'white'; // Text color
        ctx.textAlign = 'right'; // Align text to the right
        ctx.textBaseline = 'bottom'; // Align text to the bottom
        ctx.globalAlpha = 0.8; // 70% opacity

        // Add the text
        const text = `silksecret.me/${session.value.username}`;
        const x = canvas.width - 20; // 20px padding from the right
        const y = canvas.height - 10; // 10px padding from the bottom
        ctx.fillText(text, x, y);

        const blob = await canvasToBlob(canvas);
        const file = new File([blob], imageFile.name, {
          type: 'image/png',
        });

        const image: UploadMedia = {
          id: '',
          type: 'IMAGE',
          url: URL.createObjectURL(file),
          file: file,
          uploading: true,
          uploaded: false,
          failed: false,
          uploadUrl: '',
        };

        images.push(image);
      } catch (error) {
        console.error('Error processing image file:', imageFile.name, error);
      }
    }

    let newPostImages = [...postImages, ...images];

    if (newPostImages.length > 10) {
      newPostImages = newPostImages.slice(0, 10);
    }

    setPostImages(newPostImages);

    uploadImages(newPostImages);
  };

  const onVideoSelected = (event) => {
    const videoFile = event.target.files[0];
    const fileSizeInBytes = videoFile.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

    if (fileSizeInMB > 100) {
      setErrorModal({
        isOpen: true,
        message: i18n.t('CreatePost.tooLargeVideo'),
      });
      return;
    }

    if (videoFile) {
      const video: UploadMedia = {
        id: '',
        type: 'VIDEO',
        url: URL.createObjectURL(videoFile),
        file: videoFile,
        uploading: true,
        uploaded: false,
        failed: false,
        uploadUrl: '',
      };
      setPostVideo(video);
      uploadVideo(video);
    }
  };

  const uploadImages = async (images: UploadMedia[]) => {
    setUploadingMedia(true);
    for (let i = 0; i < images.length; i++) {
      const image = images[i];

      if (image.uploaded) continue;

      const updatedImageFile = await uploadImage(image);

      setPostImages((preview) => {
        const newPostImages = [...preview];
        newPostImages[i] = updatedImageFile;
        return newPostImages;
      });
    }
    setUploadingMedia(false);
  };

  const uploadImage = (image: UploadMedia) => {
    return new Promise<UploadMedia>(async (resolve) => {
      const newImageFile: UploadMedia = { ...image };

      try {
        const uploadUrl = await getImageUploadUrl();
        const uploadMediaResponse = await uploadMedia(uploadUrl, image.file);

        newImageFile.id = uploadMediaResponse.id;
        newImageFile.uploadUrl = uploadMediaResponse.variants[0];
        newImageFile.uploading = false;
        newImageFile.uploaded = true;
        newImageFile.failed = false;
      } catch (error) {
        newImageFile.failed = true;
        newImageFile.uploading = false;
      } finally {
        resolve(newImageFile);
      }
    });
  };

  const uploadVideo = async (video: UploadMedia) => {
    const newVideoFile: UploadMedia = { ...video };

    setUploadingMedia(true);

    try {
      const uploadVideo = await getVideoUploadUrl();
      await uploadMedia(uploadVideo.uploadURL, video.file);

      newVideoFile.id = uploadVideo.uid;
      newVideoFile.uploadUrl = uploadVideo.uploadURL;
      newVideoFile.uploading = false;
      newVideoFile.uploaded = true;
      newVideoFile.failed = false;
    } catch (error) {
      newVideoFile.failed = true;
      newVideoFile.uploading = false;
    } finally {
      setUploadingMedia(false);
      setPostVideo(newVideoFile);
    }
  };

  const refreshUploadImage = async (imageFileIndex: number) => {
    setPostImages((preview) => {
      const newPostImages = [...preview];
      newPostImages[imageFileIndex].uploading = true;
      newPostImages[imageFileIndex].failed = false;
      return newPostImages;
    });

    const updatedImageFile = await uploadImage(postImages[imageFileIndex]);

    setPostImages((preview) => {
      const newPostImages = [...preview];
      newPostImages[imageFileIndex] = updatedImageFile;
      return newPostImages;
    });
  };

  const refreshUploadVideo = () => {
    const newPostVideo: UploadMedia = { ...postVideo, failed: false };
    setPostVideo(newPostVideo);

    uploadVideo(newPostVideo);
  };

  const onRemoveImage = useCallback(
    (imageFileIndex: number) => {
      let newPostImages = [
        ...postImages.slice(0, imageFileIndex),
        ...postImages.slice(imageFileIndex + 1),
      ];

      if (imageFileIndex === 0 && postImages.length === 1) {
        setImagePreviewModal(false);
        window.location.reload();
      } else if (imageFileIndex === postImages.length - 1) {
        setSelectedImageIndex(0);
      }

      setPostImages(newPostImages);
    },
    [postImages]
  );

  const handleApiError = useCallback((error: AxiosError) => {
    switch (error.response?.status) {
      case 401: {
        logout();
        break;
      }
    }
  }, []);

  const postContent = async () => {
    try {
      setLoading(true);

      const mediaKeys = [];
      if (postVideo) {
        mediaKeys.push(postVideo.id);
        await post(text, 'VIDEO', mediaKeys);
      } else {
        for (const image of postImages) {
          if (image.id) {
            mediaKeys.push(image.id);
          }
        }
        await post(text, 'IMAGE', mediaKeys);
        setPostImages([]);
      }
      setText('');
      onPost();
      setPostVideo(null);
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const isUploadMediaFailed = useMemo(() => {
    const existsImageUploadFailure = postImages.find(
      (image) => image.failed === true
    );

    return postVideo?.failed || !!existsImageUploadFailure;
  }, [postVideo, postImages]);

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
      {loading ? (
        <div className="align-self-center d-flex flex-column justify-content-center align-items-center">
          <Spinner animation="border" role="status" className="text-warning" />
          <span className="text-grey-500 m-2">
            {i18n.t<string>('CreatePost.publishing')}
          </span>
        </div>
      ) : (
        <>
          <div className="card-body p-0 mt-3">
            <TextArea
              onChangeText={setText}
              placeholder={i18n.t<string>('CreatePost.placeholder')}
            />
          </div>
          {postVideo && (
            <div className="d-flex flex-column align-items-center justify-content-center mt-2">
              <div className="d-flex position-relative justify-content-center align-items-center">
                <video
                  controls
                  width="320"
                  height="240"
                  style={{ opacity: uploadingMedia ? 0.5 : 1 }}
                >
                  <source src={postVideo.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {postVideo.failed && (
                  <>
                    <i
                      className="font-xl text-warning feather-alert-circle me-2"
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 0,
                        right: -10,
                        zIndex: 1,
                      }}
                    />
                    <i
                      onClick={refreshUploadVideo}
                      className="display2-size text-warning feather-refresh-ccw me-2"
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1,
                      }}
                    />
                  </>
                )}
                {uploadingMedia && (
                  <div className="position-absolute d-flex flex-column justify-content-center align-items-center">
                    <Spinner
                      animation="border"
                      role="status"
                      className="text-warning font-xs mt-2"
                    />
                    <span className="font-xs fw-500 text-grey-800 mt-1">
                      {i18n.t<string>('CreatePost.uploading')}
                    </span>
                  </div>
                )}
              </div>
              <i
                onClick={() => {
                  setPostVideo(null);
                  window.location.reload();
                }}
                className="font-md text-warning feather-trash-2 mt-2"
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}
          <div
            style={{
              overflow: 'auto',
              flexDirection: 'row',
              display: 'flex',
              padding: 20,
            }}
          >
            {postImages.map((image, index) => (
              <div
                key={image.url}
                className="d-flex flex-column justify-content-end align-items-center position-relative"
              >
                <img
                  onClick={() => {
                    setImagePreviewModal(true);
                    setSelectedImageIndex(index);
                  }}
                  src={image.url}
                  alt="img preview"
                  style={{
                    maxWidth: '100px',
                    maxHeight: '100px',
                    margin: '5px',
                    cursor: 'pointer',
                    borderRadius: 10,
                    opacity: image.uploading || image.failed ? 0.2 : 1,
                  }}
                />
                {image.failed && (
                  <>
                    <i
                      tabIndex={index}
                      className="font-md text-warning feather-alert-circle me-2"
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 0,
                        right: -10,
                        zIndex: 1,
                      }}
                    />
                    <i
                      tabIndex={index}
                      onClick={() => refreshUploadImage(index)}
                      className="font-xl text-warning feather-refresh-ccw me-2"
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1,
                      }}
                    />
                  </>
                )}
                {image.uploading ? (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className="text-warning mt-2"
                  />
                ) : (
                  <i
                    tabIndex={index}
                    onClick={() => onRemoveImage(index)}
                    className="font-md text-warning feather-trash-2 me-2"
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="card-body d-flex p-0 mt-3 justify-content-between">
            <div className="d-flex flex-row">
              <button
                disabled={postVideo ? true : false}
                onClick={() => {
                  inputFile.current.click();
                }}
                className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 border-0 bg-transparent"
                style={{ cursor: 'pointer', opacity: postVideo ? 0.5 : 1 }}
              >
                <i className="font-md text-warning feather-image me-2"></i>
                <span>{i18n.t<string>('CreatePost.addPhotos')}</span>
              </button>
              <button
                disabled={postImages.length ? true : false}
                onClick={() => {
                  videoFile.current.click();
                }}
                className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 border-0 bg-transparent"
                style={{
                  cursor: 'pointer',
                  opacity: postImages.length ? 0.5 : 1,
                }}
              >
                <i className="font-md text-warning feather-video me-2"></i>
                <span>{i18n.t<string>('CreatePost.addVideo')}</span>
              </button>
            </div>

            <canvas ref={canvasRef} style={{ display: 'none' }} />

            <input
              type="file"
              id="file"
              ref={inputFile}
              multiple
              onChange={onPhotosSelected}
              style={{ display: 'none' }}
              accept="image/*"
            />

            <input
              id="video-input"
              type="file"
              ref={videoFile}
              accept="video/*"
              style={{ display: 'none' }}
              onChange={onVideoSelected}
            />

            <button
              onClick={postContent}
              className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 border-0 bg-transparent"
              style={{
                cursor: 'pointer',
                opacity:
                  (!postImages.length && !text) ||
                  (!postVideo && !text) ||
                  isUploadMediaFailed ||
                  uploadingMedia
                    ? 0.5
                    : 1,
              }}
              disabled={
                (!postImages.length && !text) ||
                (!postVideo && !text) ||
                isUploadMediaFailed ||
                uploadingMedia
              }
            >
              <i className="font-md text-warning feather-send me-2"></i>
              <span className="d-none-xs">
                {i18n.t<string>('CreatePost.post')}
              </span>
            </button>
          </div>
        </>
      )}
      <ImageViewer
        media={postImages}
        show={imagePreviewModal}
        onHide={() => {
          setImagePreviewModal(false);
        }}
        onRemoveImage={onRemoveImage}
        startImageIndex={selectedImageIndex}
      />
      <ErrorModal
        isOpen={errorModal.isOpen}
        message={errorModal.message}
        onClose={() => {
          setErrorModal({ isOpen: false });
          window.location.reload();
        }}
      />
    </div>
  );
};

export default CreatePost;
