import React from 'react';
import i18n from '../utils/i18n';

type Props = {
  onSubscribe: () => void;
  value: number;
}

const SubscribeCard: React.FC<Props> = ({ onSubscribe, value }) => {
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
      <div className="card-body d-block p-0 mb-3">
        <div className="col-sm-12 p-1 d-flex justify-content-center flex-column align-items-center">
          <img
            src="assets/images/logo-only.png"
            className="login-logo"
            width={100}
            alt=""
          />
        </div>
      </div>
      <button
        onClick={onSubscribe}
        className="btn p-2 m-2 w-100 align-self-center rounded-md bg-warning font-xssss fw-700 text-white"
      >
        {i18n.t<string>('ProfileCard.subscribe', { value })}
      </button>
    </div>
  );
};

export default SubscribeCard;
