import axios, { AxiosResponse } from 'axios';
import { session } from '../signals/session';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const savePlan = (value: number) => {
  return new Promise<AxiosResponse>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.post(
        `${REACT_APP_API_URL}/plan`,
        { value },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};
